<template>
    <login-bgd>
        <el-alert class="err-info"
                  v-if="isShowErr"
                  title="错误提示"
                  type="error"
                  :description="errMsg"
                  show-icon>
        </el-alert>
        <el-card style="margin-right: 180px;width: 360px;">
            <div slot="header" class="clearfloat" style="text-align: center">
                <span>后台系统</span>
            </div>
            <el-form :model="loginInfo" ref='loginInfo' label-width="0" :rules="loginInfoRules"
                     @keydown.native.enter='handleLogin' style="margin-buttom: -40px;">
                <el-form-item prop='userName'>
                    <el-input type="text" v-model="loginInfo.userName" auto-complete="off"
                              placeholder="请输入手机号">
                        <i slot="prefix" class="el-input__icon el-icon-user" style="font-size: 18px;"></i>
                    </el-input>
                </el-form-item>
                <el-form-item prop='password'>
                    <el-input :type="passwordType" v-model="loginInfo.password" auto-complete="off"
                              placeholder="6-16位密码">
                        <i slot="prefix" class="el-input__icon el-icon-lock" style="font-size: 18px;"></i>
                    </el-input>
                    <i class='el-icon-view showPwd' @click="showPwd"></i>
                </el-form-item>
                <el-form-item prop='captcha'>
                    <el-input type="text" v-model="loginInfo.captcha" maxlength="4" placeholder="验证码"
                              style='width: 62%;float: left;'></el-input>
                    <img style="float: left;width:36%;height: 40px;cursor: pointer;margin-left: 2%;border-radius: 4px;"
                         @click="changeCaptcha" :src="captchaImg"/>
                </el-form-item>
                <el-form-item>
                    <el-button style='border-radius: 6px;' type="primary" class="w100"
                               @click="handleLogin">登录
                    </el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </login-bgd>
</template>
<script>
    import LoginBgd from '@/components/LoginBgd'

    class LoginInfo {
        constructor() {
            this.userName = null;
            this.password = null;
            this.captcha = null;
            this.randomId = null;
        }

        reset() {
            this.constructor();
        }
    }

    let fromUrl = null;

    export default {
        components: {
            LoginBgd
        },
        data() {
            return {
                passwordType: 'password',
                loginInfo: new LoginInfo(),
                captchaImg: null,
                loginInfoRules: {
                    userName: [{
                        validator: function (rule, value, callback) {
                            if (value) {
                                // if (/^1[3|5|9|8|7]\d{9}$/.test(value) == false && value != 'admin') {
                                //     callback(new Error("请输入正确的手机号"))
                                // } else {
                                callback();
                                // }
                            } else {
                                callback(new Error("请输入手机号"))
                            }
                        },
                        trigger: 'blur'
                    }],
                    password: [{
                        validator: function (rule, value, callback) {
                            if (value) {
                                if (/^[A-Za-z0-9]{6,16}$/.test(value) == false) {
                                    callback(new Error("请输入正确的密码"))
                                } else {
                                    callback();
                                }
                            } else {
                                callback(new Error("请输入密码"))
                            }
                        },
                        trigger: 'blur'
                    }],
                    captcha: [{
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur'
                    }],
                },
                errMsg: null,
                isShowErr: false,
            }
        },
        beforeRouteEnter(to, from, next) {
            if(from.name){
                fromUrl = from.name;
            }
            next()
        },
        created() {
            if(fromUrl && this.isLoginOut){
                this.$message.warning('登录已失效');
                this.isLoginOut = false;
            }
            this.changeCaptcha();
        },
        methods: {
            showPwd() {
                this.passwordType = this.passwordType == 'password' ? 'text' : 'password';
            },
            changeCaptcha() {
                let uuid = this.uuid();
                this.loginInfo.randomId = uuid;
                this.captchaImg = 'api/sys/getCaptcha/' + uuid + '?' + new Date();
            },
            handleLogin() {
                // setTimeout(() => {
                //     this.isShowErr = false;
                // }, 5000);
                if (!this.loginInfo.userName) {
                    this.$message.error('用户名不能为空！');
                    // this.errMsg = '用户名不能为空！';
                    // this.isShowErr = true;
                    return;
                }
                if (!this.loginInfo.password) {
                    this.$message.error('密码不能为空！');
                    // this.errMsg = '密码不能为空！';
                    // this.isShowErr = true;
                    return;
                }
                if (/^[A-Za-z0-9]{6,16}$/.test(this.loginInfo.password) == false) {
                    this.$message.error('请输入6-16位的密码！');
                    // this.errMsg = '请输入6-16位的密码！';
                    // this.isShowErr = true;
                    return;
                }
                if (!this.loginInfo.captcha) {
                    this.$message.error('验证码不能为空！');
                    // this.errMsg = '验证码不能为空！';
                    // this.isShowErr = true;
                    return;
                }
                this.getPublicKey();
            },
            uuid() {
                var s = [];
                var hexDigits = "0123456789abcdefghijklmnopqrstuvwxyz";
                for (var i = 0; i < 32; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                var uuid = s.join("");
                return uuid;
            },
            // handleLogin() {
            //     this.$refs.loginInfo.validate((valid) => {
            //         if (valid) {
            //             if (!this.loginInfo.captcha) {
            //                 this.changeCaptcha();
            //             } else {
            //                 this.getPublicKey();
            //             }
            //
            //             // if (this.loginInfo.password == '123456') {
            //             //     sessionStorage.setItem("userId", 1);
            //             //     this.$router.replace({path: '/'});
            //             // } else {
            //             //     this.$message.warning('正确密码为123456');
            //             //     return false;
            //             // }
            //             // let res = await this.$http.post('/hero/getHeroList', param);
            //         } else {
            //             console.log('error submit!!');
            //             return false;
            //         }
            //     })
            // },
            async login(data) {
                let loginParam = JSON.parse(JSON.stringify(this.loginInfo));
                let publicKey = RSAUtils.getKeyPair(data.exponent, '', data.module);
                let encrptPw = RSAUtils.encryptedString(publicKey, loginParam.password.split("").reverse().join(""));
                loginParam.password = encrptPw;
                let res = await this.$http.post('/sys/login', loginParam);
                console.log("res="+JSON.stringify(res));
                this.loginInfo.captcha = null;
                if (res.state) {
                    sessionStorage.setItem("userId", res.data.userId);
                    this.$router.replace({path: '/'});
                } else {
                    if (res.msg) {
                        this.changeCaptcha();
                        // this.isShowErr = true;
                        // setTimeout(() => {
                        //     this.isShowErr = false;
                        // }, 5000);
                    }
                }
            },
            async getPublicKey() {
                let param = {userName: this.loginInfo.userName};
                let res = await this.$http.post('/security/getPublicKey', param);
                if (res.state) {
                    this.login(res.data);
                }
            },

        }
    }
</script>

<style scoped lang="scss">
    .login-title {
        margin: 0 0 20px;
        text-align: center;
        color: #409eff;
        letter-spacing: 3px;
    }

    .showPwd {
        color: #909399;
        cursor: pointer;
        position: absolute;
        top: 13px;
        right: 10px;
    }

    .w100 {
        width: 100%;
    }

    .err-info {
        position: absolute;
        left: 50%;
        top: 200px;
        width: 600px;
        margin-left: -300px;
    }
</style>
